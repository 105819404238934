import * as React from 'react'
import * as css from './MarketingMaterials.module.css'
import { LibraryContext } from './LibraryContext'
import Filter from './Filter'
import Material from './Material'

const MarketingMaterials = () => {
  const {
    allMaterials,
    allProductPg,
    allFileTypes,
    $filter,
  } = React.useContext(LibraryContext)

  const filteredMaterials = (!!$filter['Products'] || !!$filter['File types'])
    ? allMaterials.filter(({ meta, file }) => {
      let filteredProducts = !!$filter['Products'] && meta.products.map(({ title }) => title).includes($filter['Products'])
      let filteredFileTypes = !!$filter['File types'] && file.type === $filter['File types']

      return (!!$filter['Products'] && !!$filter['File types'])
        ? (filteredProducts && filteredFileTypes)
        : (filteredProducts || filteredFileTypes)
    })
    : allMaterials

  return (
    <section className="container padding">
      <Filter title="Products" list={allProductPg} />

      <Filter title="File types" list={allFileTypes} />

      <div className={css.list}>
        {filteredMaterials.map((material, key) => (
          <Material material={material} key={material.meta.title + key} />
        ))}
      </div>
    </section>
  )
}

export default MarketingMaterials
