import * as React from 'react'
import * as css from './Filter.module.css'
import { FaFilter } from 'react-icons/fa'
import FilterButton from './FilterButton'

const Filter = ({ title, list }) => (
  <div className={css.filter}>
    <div className={`${css.label} text-light with-icon`}>
      <FaFilter />
      {title || 'Filter'}
    </div>

    {list?.map((product, key) => (
      <FilterButton title={title} target={product} key={key} />
    ))}
  </div>
)

export default Filter
