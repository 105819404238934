import * as React from 'react'
import DefaultLayout from 'layouts/Default'
import LoadingLayout from 'layouts/Loading'
import { LibraryProvider } from 'components/pg-library/LibraryContext'
import { graphql, useStaticQuery } from 'gatsby'
import HeroWithBg from 'components/common/HeroWithBg'
import MarketingMaterials from 'components/pg-library/MarketingMaterials'
import { withAuthenticationRequired } from '@auth0/auth0-react'

const App = () => {
  const { sanityLibraryPg: pg } = useStaticQuery(query)

  return (
    <DefaultLayout {...pg.seo}>
      <LibraryProvider>
        <HeroWithBg {...pg.hero} />
        <MarketingMaterials />
      </LibraryProvider>
    </DefaultLayout>
  )
}

const MarketingLibraryPg = withAuthenticationRequired(App, {
  onRedirecting: () => <LoadingLayout />
})

export default MarketingLibraryPg

const query = graphql`query MarketingLibraryPg {
  sanityLibraryPg {
    hero { ...hero }
    seo {
      title
      description
    }
  }
}`
