import * as React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

export const LibraryContext = React.createContext(null)

export const LibraryProvider = ({ children }) => {
  const {
    allSanityProductPg,
    allSanityMarketingMaterial,
  } = useStaticQuery(query)

  const allMaterials = allSanityMarketingMaterial.nodes
    .sort((a, b) => a.meta.title.toLowerCase() > b.meta.title.toLowerCase())

  const [$filter, set$filter] = React.useState(
    typeof window !== 'undefined'
      ? (JSON.parse(window.sessionStorage.getItem('marketing-library-filter')) || {})
      : {}
  )

  const value = {
    allMaterials,
    allProductPg: allSanityProductPg.nodes.map(pg => pg.title),
    allFileTypes: [...new Set(allMaterials.map(material => material.file.type).filter(Boolean))].sort(),
    $filter, set$filter,
  }

  return (
    <LibraryContext.Provider value={value}>
      {children}
    </LibraryContext.Provider>
  )
}

const query = graphql`query LibraryContext {
  allSanityProductPg(sort: {fields: order}) {
    nodes { title }
  }
  allSanityMarketingMaterial {
    nodes {
      meta {
        title
        description
        products { title }
      }
      file {
        type
        external
        file { asset { url } }
        thumbnail { asset { gatsbyImageData(placeholder: NONE, aspectRatio: 1.5) } }
      }
      seo { slug { current } }
    }
  }
}`
