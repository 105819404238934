import * as React from 'react'
import * as css from './FilterButton.module.css'
import { LibraryContext } from './LibraryContext'
import { IoClose } from 'react-icons/io5'
import clsx from 'clsx'

const FilterButton = ({ title, target }) => {
  const { $filter, set$filter } = React.useContext(LibraryContext)

  function updateFilter(target) {
    set$filter(filter => ({
      ...filter,
      [title]: target === $filter[title] ? false : target,
    }))
  }

  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      window.sessionStorage.setItem('marketing-library-filter', JSON.stringify($filter))
    }
  }, [$filter])

  return (
    <button
      className={clsx('relative', target === $filter[title] ? 'action' : 'button')}
      onClick={() => updateFilter(target)}
    >
      {target}

      <span className={css.close} hidden={target !== $filter[title]}>
        <IoClose />
      </span>
    </button>
  )
}

export default FilterButton
