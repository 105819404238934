import * as React from 'react'
import * as css from './Material.module.css'
import { Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const Material = ({ material }) => {
  const { meta, file, seo } = material

  const products = meta.products.map(({ title }) => title).join`, `

  return (
    <article className={css.root}>
      <Link className={css.link} to={`/portfolio/${ seo.slug.current }`}>
        <div className="relative">
          {file.thumbnail
            ? <GatsbyImage image={getImage(file.thumbnail.asset)} alt={meta.title} />
            : <div className={css.thumbnail}></div>
          }

          <small className={`${css.type} cps-blue uppercase`}>
            {file.type}
          </small>
        </div>

        <div className={css.txt}>
          <h2 className={css.title}><b>{meta.title}</b></h2>
          <small className={css.product}>{products}</small>
        </div>
      </Link>
    </article>
  )
}

export default Material
